<template>
  <RevContainer v-if="products.length === 0" data-spec="body">
    <NoResult class="my-32" />
  </RevContainer>
  <div v-else>
    <SkinnyBanner page="landing" />
    <RevContainer class="mb-16 mt-32" data-spec="body">
      <AboutSeller class="mb-36 mt-28" />
      <div data-spec="main">
        <section data-test="product-results-refinement">
          <div class="mb-12 justify-between lg:mb-32 lg:flex">
            <span />

            <SortInput
              v-model="sortInput"
              class="hidden basis-200 lg:block"
              :sort-options="sortOptions"
            />
          </div>

          <div class="lg:flex lg:gap-16">
            <div>
              <div class="hidden lg:block lg:w-256" data-test="filters-wrapper">
                <PriceFilter
                  v-if="priceFacet"
                  v-model="priceInput"
                  :avgPrice="priceFacet.avg"
                  :currency="defaultCurrency"
                  :max="priceFacet.max"
                  :prices="priceFacet.values"
                  :step="priceFacet.step"
                  variant="default"
                />
                <FacetFilter
                  v-for="facet in facets"
                  :key="facet.name"
                  v-model="filtersInput[facet.name]"
                  class="mt-32"
                  :disabled="false"
                  :name="facet.name"
                  :title="facet.title"
                  tracking="product list > side filter"
                  :type="facet.type"
                  :values="facet.values"
                  variant="default"
                />
              </div>
            </div>
            <div v-if="products" class="w-full">
              <ActiveFilters
                v-model:filters="filtersInput"
                v-model:price="priceInput"
                class="mb-8 md:mb-32"
                :facets="facets"
                :max-price="priceFacet?.max ?? 0"
                :search-nb-results="total"
              />
              <ResultGrid :products="products" />
            </div>
          </div>

          <Pagination v-model="pageInput" :page-count="pageCount" />
        </section>
      </div>
    </RevContainer>
    <ActionStickyBar class="lg:hidden" :facets-count="facetCount" />
    <FullscreenFilters
      v-if="displayed"
      v-model:filters="filtersInput"
      v-model:price="priceInput"
      v-model:sort="sortInput"
      :facets="facets"
      :price-facet="priceFacet"
      :search-nb-results="total"
      :sort-options="sortOptions"
    />
  </div>
</template>

<script lang="ts" setup>
import {
  createError,
  useHead,
  useRoute,
  useRouteParams,
  useRuntimeConfig,
} from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevContainer } from '@ds/components/Container'

import SkinnyBanner from '~/scopes/branding/components/SkinnyBanner/SkinnyBanner.vue'
import {
  getSortOptions,
  useAlgoliaApiKey,
  useAlgoliaConfiguration,
} from '~/scopes/search/helpers/helpers'
import { getMetas } from '~/utils/seo/meta'

import { useProductsSearch } from '../../search/composables/useProductsSearch'
import AboutSeller from '../components/AboutSeller.vue'
import ActionStickyBar from '../components/ActionStickyBar.vue'
import ActiveFilters from '../components/ActiveFilters.vue'
import FullscreenFilters from '../components/FullscreenFilters.vue'
import NoResult from '../components/NoResult.vue'
import Pagination from '../components/Pagination.vue'
import FacetFilter from '../components/Refinements/FacetFilter.vue'
import PriceFilter from '../components/Refinements/PriceFilter.vue'
import ResultGrid from '../components/ResultGrid.vue'
import SortInput from '../components/SortInput.vue'
import { useFullscreenFilters } from '../composables/useFullscreenFilters'
import { useFiltersFromHash } from '../composables/useHashFilters'
import { useSellerProductListPage } from '../composables/useSellerProductListPage'

import translations from './SellerProductListPage.translations'

const { defaultCurrency } = useMarketplace().market
const { uuid: id } = useRouteParams()

const searchConfig = await useAlgoliaConfiguration('merchant-landing', id)
if (searchConfig === null) {
  throw createError({
    statusCode: 500,
    statusMessage: 'Unable to retrieve search configuration',
    fatal: true,
  })
}
const apiKeyConfig = await useAlgoliaApiKey(searchConfig.indexType)
if (apiKeyConfig === null) {
  throw createError({
    statusCode: 500,
    statusMessage: 'Unable to get algolia API key',
    fatal: true,
  })
}

const sortOptions = getSortOptions(searchConfig.indexes)

const {
  public: { ALGOLIA_ID },
} = useRuntimeConfig()

const {
  total,
  products,
  facets,
  pageCount,
  facetCount,
  priceFacet,
  filtersInput,
  sortInput,
  pageInput,
  priceInput,
} = await useProductsSearch(ALGOLIA_ID, searchConfig, apiKeyConfig.apiKey)

const { displayed } = useFullscreenFilters()

useFiltersFromHash(filtersInput, priceInput, pageInput)

const i18n = useI18n()
const route = useRoute()
const { company } = useSellerProductListPage(id)
const metaImageUrl = '/img/plp/relovethepreloved.png'
const {
  public: { COMPANY_NAME },
} = useRuntimeConfig()
const pageNumber = Array.isArray(route.query.p)
  ? route.query.p[0]
  : route.query.p
useHead({
  title: pageNumber
    ? i18n(translations.landingSellerMetaTitleWithPage, {
        sellerName: company.value,
        pageNumber: parseInt(pageNumber, 10) + 1,
      })
    : i18n(translations.landingSellerMetaTitleNew, {
        sellerName: company.value,
        merchantName: company.value,
      }),
  titleTemplate: `%s | ${COMPANY_NAME}`,
  meta: getMetas({
    content: i18n(translations.landingSellerMetaDescriptionNew, {
      sellerName: company.value,
      merchantName: company.value,
    }),
    image: metaImageUrl,
    'og:type': 'website',
  }),
  link: [
    {
      href: route.fullPath,
      rel: 'canonical',
    },
  ],
})
</script>
