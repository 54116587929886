export default {
  reviewLink: {
    id: 'landing_seller_performances_block1_reviews',
    defaultMessage: 'Backers Satisfaction: {rank, number}/{maxRating, number}',
  },
  landingSellerAge: {
    id: 'landing_seller_performances_lifespan',
    defaultMessage: 'Lifespan on Back Market: {year} years, {month} months',
  },
  landingSellerDescriptionTitle: {
    id: 'landing_seller_description_title',
    defaultMessage: 'About the refurbisher',
  },
  landingSellerDescriptionVat: {
    id: 'landing_seller_description_vat',
    defaultMessage: 'EIN number:',
  },
  landingSellerDescriptionSiret: {
    id: 'landing_seller_description_siret',
    defaultMessage: 'SIRET N°:',
  },
  landingSellerDescriptionAddress: {
    id: 'landing_seller_description_address',
    defaultMessage: 'Address:',
  },
  landingSellerCountryOrigin: {
    id: 'landing_seller_description_country_of_origin',
    defaultMessage: 'Seller country of origin:',
  },
  konbiniCompanyName: {
    id: 'landing_seller_konbini_company_name',
    defaultMessage: 'Company Name:',
  },
  konbiniStoreName: {
    id: 'landing_seller_konbini_store_name',
    defaultMessage: 'Store Name:',
  },
  konbiniEmail: {
    id: 'landing_seller_konbini_email',
    defaultMessage: 'Email address:',
  },
  konbiniPhoneNumber: {
    id: 'landing_seller_konbini_phone',
    defaultMessage: 'Phone number:',
  },
  konbiniSecondHandDealerNumber: {
    id: 'landing_seller_konbini_secondhand_dealer_number',
    defaultMessage: 'Second hand dealer number:',
  },
  konbiniStoreOperationManager: {
    id: 'landing_seller_konbini_store_operation_manager',
    defaultMessage: 'Business operation manager:',
  },
  konbiniStoreSecurityOfficer: {
    id: 'landing_seller_konbini_store_security_officer',
    defaultMessage: 'Business security officer:',
  },
  konbiniPhoneExplanationNotice: {
    id: 'landing_seller_konbini_phone_explanation_notice',
    defaultMessage: 'Please do not call the seller directly.',
  },
}
