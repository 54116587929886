export default {
  landingSellerMetaTitleNew: {
    id: 'landing_seller_meta_title_new',
    defaultMessage: 'Refurbisher {sellerName}',
  },

  landingSellerMetaTitleWithPage: {
    id: 'landing_seller_meta_title_page',
    defaultMessage: 'Refurbisher {sellerName} - Page {pageNumber}',
  },

  landingSellerMetaDescriptionNew: {
    id: 'landing_seller_meta_description_new',
    defaultMessage:
      'Come discover the refurbisher {sellerName}. Buy refurbished products backed by warranties on Back Market.',
  },
}
